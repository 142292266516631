import React from 'react'
import AppRouter from './routes/AppRouter'

export default function Setau() {
  return (
    <div className="wrapper">
      <AppRouter />
    </div>
  )
}
